import React from 'react'

import CierreCajaService from '../../../../services/cierre-caja-service'
import { UIContext } from '../../../../contexts/uiContext'

import '../../../../App.css'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Button from "react-bootstrap/Button";

import { Link } from "react-router-dom";

import DatePicker from '../../../shared/date-picker';
import { formatCurrency } from "../../../shared/currency-input";
import { BsFillPrinterFill } from "react-icons/bs";
import UtilsService from '../../../../services/utils-service';
import AdminTable from '../../../shared/admin-table';

function CierresList() {

  const [cierres, setCierres] = React.useState([])

  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());

  const { showMessage, printDataUrl, setLoading } = React.useContext(UIContext);

  const [rowsCount, setRowsCount] = React.useState(0);

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(() => [
    {
      header: '#',
      accessorKey: 'id',
    },
    {
      header: 'Usuario',
      accessorKey: 'usuario',
    },
    {
      header: 'Fecha',
      accessorKey: 'fecha',
      accessorFn: (row) => UtilsService.formatDateTime(row.fecha),
    },
    {
      header: 'Monto inicial',
      accessorKey: 'montoInicial',
      accessorFn: (row) => formatCurrency(row.montoInicial),
    },
    {
      header: 'Total',
      accessorKey: 'montoTotal',
      accessorFn: (row) => formatCurrency(row.montoTotal),
    },
    {
      header: 'Acciones',
      accessorKey: 'id',
      id: 'acciones',
      cell: ({ row }) => {
        return <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "4px 8px",
          justifyContent: "center",
        }}>
          <span style={{ cursor: 'pointer' }}>
            <BsFillPrinterFill onClick={() => reimprimirCierre(row.original.id)} />
          </span>
        </div>
      },
    },
  ], [cierres]);


  React.useEffect(() => {
    setLoading(true)
    CierreCajaService.getCierreCaja(pagination.pageIndex, pagination.pageSize, startDate, endDate)
      .then(data => {
        setCierres(data.content)
        console.log("data ELements", data.totalElements);
        setRowsCount(data.totalElements);
      })
      .finally(() => setLoading(false)
      )
  }, [pagination.pageIndex, pagination.pageSize, startDate, endDate])

  const reimprimirCierre = id => {
    setLoading(true)
    CierreCajaService.getReporteCierre(id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    }).finally(() => setLoading(false))
  }

  return (
    <Container className="central-div">
      <Row>
        <Col>
          <h1>
            Cierre de caja
          </h1>
          <Link to={"/cierres/new"} style={{ float: 'right' }}>
            <Button >
              Cerrar caja ahora
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col md={6}>
          <DatePicker selected={startDate}
            onChangeRaw={e => e.preventDefault()}
            onChange={date => setStartDate(date)}
          />
        </Col>
        <Col md={6}>
          <DatePicker selected={endDate}
            onChangeRaw={e => e.preventDefault()}
            onChange={date => setEndDate(date)}
          />
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <AdminTable  columns={columns} data={cierres} pagination={pagination}  
          onPaginationChange={setPagination} rowCount={rowsCount}/>
          
        </Col>
      </Row>
    </Container>

  )

}

export default CierresList