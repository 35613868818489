import React, { useEffect } from 'react'

import EntradasService from '../../../../services/entradas-service';
import { UIContext } from '../../../../contexts/uiContext'

import '../../../../App.css'

import Modal from 'react-bootstrap/Modal';
import { ImCancelCircle } from "react-icons/im";
import Button from "react-bootstrap/Button";
import { FiRefreshCcw } from "react-icons/fi";
import { ADMIN_ROLE } from '../../../../config/constants'
import { UserContext } from '../../../../contexts/userContext';
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import DatePicker from '../../../shared/date-picker';
import { formatCurrency } from "../../../shared/currency-input";
import { BsFillPrinterFill } from "react-icons/bs";
import { Form } from 'react-bootstrap';
import UtilsService from '../../../../services/utils-service';
import AdminTable from '../../../shared/admin-table';


function EntradasList() {
  const { hasRole } = React.useContext(UserContext)
  const [entradas, setEntradas] = React.useState([]);
  const [rowsCount, setRowsCount] = React.useState(0);
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [usuario, setUsuario] = React.useState('');
  const [showModal, setShowModal] = React.useState(false);
  const [entradaSeleccionada, setEntradaSeleccionada] = React.useState({});
  const { showMessage, printDataUrl, setLoading } = React.useContext(UIContext)
  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const columns = React.useMemo(() => [
    {
      header: '#',
      accessorKey: 'numero',
    },
    {
      header: 'Fecha',
      accessorKey: 'fecha',
      accessorFn: (row) => UtilsService.formatDateTime(row.fecha),
    },
    {
      id: 'dni',
      header: 'DNI',
      accessorKey: 'dni',
    },
    {
      header: 'Nombre',
      accessorKey: 'nombre',
    },
    {
      header: 'Apellido',
      accessorKey: 'apellido',
    },
    {
      header: 'Dirección',
      accessorKey: 'direccion',
    },
    {
      header: 'Teléfono',
      accessorKey: 'telefono',
    },
    {
      header: 'Tipo de venta',
      accessorKey: 'abono',
      accessorFn: (row) => {
        return (row.abono) ? "ABONO" : "ENTRADA";
      }
    },
    {
      header: 'Total',
      accessorKey: 'montoTotal',
      accessorFn: (row) => formatCurrency(row.montoTotal),
    },
    {
      header: 'Estado',
      accessorKey: 'estado',
    },
    {
      header: 'Medio de pago',
      accessorKey: 'medioPago',
    },
    {
      header: 'Cerrada',
      accessorKey: 'idCierreCaja',
      accessorFn: (row) => {
        return (row.idCierreCaja) ? 'SI' : 'NO';
      }
    },
    {
      header: 'Acciones',
      accessorKey: 'id',
      cell: ({ row }) => {
        return <div style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "4px",
          justifyContent: "center",
        }}>
          <span style={{ cursor: 'pointer' }}>
            <BsFillPrinterFill onClick={() => reimprimirEntrada(row.original.id)} />
          </span>
          <span style={{ cursor: 'pointer' }}>
            {!row.original.idCierreCaja && row.original.estado === 'ACTIVO' && <ImCancelCircle onClick={() => {
              setShowModal(true);
              setEntradaSeleccionada(row.original);
            }} />}
          </span>
        </div>
      },
    },
  ], [entradas]);

  React.useEffect(() => {
    buscarEntradas()
  }, [pagination.pageIndex, pagination.pageSize, startDate, endDate])

  const reimprimirEntrada = id => {
    setLoading(true)
    EntradasService.getReporteEntrada(id).then(dataUrl => {
      printDataUrl(dataUrl);
    }).catch(error => {
      showMessage(error, 'error');
    }).finally(() => setLoading(false))
  }

  const anularEntrada = id => {
    setLoading(true)
    EntradasService.anularEntrada(id).then(data => {
      reimprimirEntrada(id)
      setShowModal(false)
      buscarEntradas()
    }).catch(error => {
      showMessage(error, 'error');
    }).finally(() => setLoading(false))
  }

  function checkKeyPress(event) {
    if (event.key === 'Enter') {
      buscarEntradas()
    }
  }

  function buscarEntradas() {
    setLoading(true);
    EntradasService.getEntradas(pagination.pageIndex, pagination.pageSize, usuario, startDate, endDate)
      .then(data => {
        setEntradas(data.content);
        setRowsCount(data.totalElements);
      })
      .finally(() => setLoading(false))
  }

  return (
    <div>
      <Container className="central-div">
        <Row>
          <Col>
            <h1>Entradas vendidas</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            <h3>Fecha desde</h3>
            <DatePicker selected={startDate}
              onChangeRaw={e => e.preventDefault()}
              onChange={date => setStartDate(date)}
            />
          </Col>
          <Col>
            <h3>Fecha hasta</h3>
            <DatePicker selected={endDate}
              onChangeRaw={e => e.preventDefault()}
              onChange={date => setEndDate(date)}
            />
          </Col>
          <Col>
            {hasRole([ADMIN_ROLE]) &&
              <>
                <h3>Usuario</h3>
                <Form.Control onKeyDown={checkKeyPress} onChange={e => setUsuario(e.target.value)} />
              </>
            }
            <Button onClick={() => {
              buscarEntradas()
            }}>
              <FiRefreshCcw />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <AdminTable columns={columns} data={entradas} pagination={pagination}
              onPaginationChange={setPagination} rowCount={rowsCount} />
          </Col>
        </Row>
      </Container>


      <Modal show={showModal} onHide={() => setShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmación</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: 'center' }} ><h4>¿Desea anular esta entrada?</h4></Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Cerrar
          </Button>
          <Button variant="primary" onClick={() => {
            anularEntrada(entradaSeleccionada.id)
          }}>
            Confirmar
          </Button>
        </Modal.Footer>
      </Modal>

    </div>
  )
}

export default EntradasList