import React from 'react'
import Table from "react-bootstrap/Table";
import { useReactTable, getCoreRowModel, flexRender, } from '@tanstack/react-table';

function AdminTable(props) {

  const table = useReactTable({
    data: props.data,
    columns: props.columns,
    debugTable: true,
    manualPagination: true,
    rowCount: props.rowCount,
    getCoreRowModel: getCoreRowModel(),
    onPaginationChange: props.onPaginationChange,
    state: { pagination: props.pagination },
  });

  return (
    <div>
      <Table striped bordered hover responsive size="sm">
        <thead style={{ textAlign: 'center' }}>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (

                <th key={header.id}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}</th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id} style={{ textAlign: 'center' }}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>

      <div className="flex items-center gap-2">
        <button
          className="border rounded p-2"
          onClick={() => table.firstPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<<'}
        </button>
        <button
          className="border rounded p-2"
          onClick={() => table.previousPage()}
          disabled={!table.getCanPreviousPage()}
        >
          {'<'}
        </button>

        {Array.from({ length: table.getPageCount() }).map((_, index) => (
          <button key={index}
            className={`border rounded p-2 ${table.getState().pagination.pageIndex === index ? '!bg-blue-500 !text-white' : 'bg-transparent text-black'}`}
            onClick={() => table.setPageIndex(index)}>
            {index + 1}
          </button>
        ))}

        <button
          className="border rounded p-2"
          onClick={() => table.nextPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>'}
        </button>
        <button
          className="border rounded p-2"
          onClick={() => table.lastPage()}
          disabled={!table.getCanNextPage()}
        >
          {'>>'}
        </button>

        <span className="flex items-center gap-2">
          <span>Página </span>
          <strong>
            {table.getState().pagination.pageIndex + 1} de {' '}
            {table.getPageCount()}
          </strong>
        </span>
        <span className="flex items-center gap-2"> | Resultados por página: </span>
        <select
          value={table.getState().pagination.pageSize}
          onChange={e => {
            table.setPageSize(Number(e.target.value))
          }}
        >
          {[10, 20, 30, 40, 50].map((el) => (
            <option key={el} value={el} className="flex items-center gap-3">
              {el}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

export default AdminTable;