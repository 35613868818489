import axios from 'axios';
import UtilsService from './utils-service'

function fromServer(cierre) {
  if (cierre.fecha) {
    cierre.fecha = new Date(cierre.fecha)
  }
  return cierre
}

const CierreCajaService = {
  
  getCierreCaja: (pageNumber, pageSize, startDate, endDate) => {
    const  params= {
      page: pageNumber ? pageNumber : 0,
      size: pageSize ? pageSize : 10,
      startDate: UtilsService.formatDateToServer(startDate),
      endDate: UtilsService.formatDateToServer(endDate),
    }
  return axios.get('/api/polideportivo/cierre-caja', { params: params }).then(resp => {
    resp.data.content.forEach(fromServer)
      return resp.data
    });
},
createCierreCaja: cierreCaja => {
  return axios.post('/api/polideportivo/cierre-caja', cierreCaja).then(resp => resp.data)
},
  getReporteCierre: (id) => {
    return UtilsService.getUrlDataFromPdfEndpoint(`/api/polideportivo/cierre-caja/${id}/reporte`)
  }
  
}

export default CierreCajaService